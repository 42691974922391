import { spreadsheetId, configSheetRange } from "../../env"
import { fetchGoogleSheetValues, MajorDimension } from "../services/googleSheets";
import { Config } from "./model/config";

const SHOW_SCHEDULE_ON_HOME_INDEX = 0;

class ConfigBroker {

    async getConfig() {
        const response = await fetchGoogleSheetValues(spreadsheetId, configSheetRange, MajorDimension.ROWS);
        const responseContent = await response.json();
        const rows:Array<Array<string>> = responseContent.values;
        return new Config(
            rows[SHOW_SCHEDULE_ON_HOME_INDEX][0] === "TRUE"
        );
    }

}

const configBroker = new ConfigBroker();
export { configBroker };
