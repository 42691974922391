import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Default } from "../pages/default/default";
import { SchedulePage } from "../pages/schedule/schedule";
import { HostPage } from "../pages/host/host";

import "./app.css";

export const App = () =>
    <BrowserRouter>
        <Routes>
            <Route index element={<Default />} />
            <Route path="schedule" element={<SchedulePage />} />
            <Route path="hosts" element={<HostPage />} />
        </Routes>
    </BrowserRouter>
