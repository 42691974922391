import "./schedule.css";

import { peformanceEventBroker, PerformanceEvent } from "../../../domain/events/performance-events";
import { useState, useEffect } from "react"
import { EventItem } from "../../components/eventItem/eventItem";

export const SchedulePage = () => {
    const [ events, setEvents ] = useState([] as Array<PerformanceEvent>);

    useEffect(() => {
        async function getEvents() {
            const events = await peformanceEventBroker.getEvents();
            setEvents(events);
        }
        getEvents().then(_ => {
            setInterval(getEvents, 5000);
        });
    }, [])

    if (events.length === 0)
        return <>Loading...</>;

    const now = new Date();
    const current = events.find(e => e.startTime.isBefore(now) && e.endTime.isAfter(now));
    const upNext = events.filter(e => e.startTime.isSameOrAfter(now)).splice(0, current ? 2 : 3);

    return <>
        <div className="view-schedule__container">
            { current &&
            <div className="view-schedule__events view-schedule__current">
                <EventItem event={current} key={current.name + current.startTime.toJSON()} />
            </div>}

            { upNext.length > 0 &&
            <div className="view-schedule__events view-schedule__upNext">
                {upNext.map((event, i) =>
                    <EventItem event={event} key={event.name + event.startTime.toJSON()} />
                )}
            </div> }
        </div>
    </>;
}