const sheetsApiUrl = process.env["REACT_APP_BGB_SHEETS_API_URI"] as string;
const sheetsAccessKey = process.env["REACT_APP_BGB_SHEETS_ACCESS_KEY"] as string;
const spreadsheetId = process.env["REACT_APP_BGB_SPREADSHEET_ID"] as string;

const performanceScheduleSheetRange = process.env["REACT_APP_BGB_PERFORMANCE_SCHEDULE_RANGE"] as string;
const hostScheduleSheetRange = process.env["REACT_APP_BGB_HOST_SCHEDULE_RANGE"] as string;
const configSheetRange = process.env["REACT_APP_BGB_CONFIG_RANGE"] as string;


let errors = "";
if (!sheetsApiUrl)
    errors += "Sheets Api Url is not set! Ensure it's set in the environment variable REACT_APP_BGB_SHEETS_API_URI at compilation.\n";
if (!spreadsheetId)
    errors += "Google Sheet Id is not set! Ensure it's set in the environment variable REACT_APP_BGB_SPREADSHEET_ID at compilation.\n";


if (!performanceScheduleSheetRange)
    errors += "Sheet Range for Schedule is not set! Ensure it's set in the environment variable REACT_APP_BGB_PERFORMANCE_SCHEDULE_RANGE at compilation.\n";
if (!hostScheduleSheetRange)
    errors += "Sheet Range for Schedule is not set! Ensure it's set in the environment variable REACT_APP_BGB_HOST_SCHEDULE_RANGE at compilation.\n";
if (!configSheetRange)
    errors += "Sheet Range for Configuration is not set! Ensure it's set in the environment variable REACT_APP_BGB_CONFIG_RANGE at compilation.\n";

if (!sheetsAccessKey)
    errors += "API Access Key is not set! Ensure it's set in the environment variable REACT_APP_BGB_SHEETS_ACCESS_KEY at compilation.\n";

if (errors.length > 0)
    throw new Error(errors);


export {
    sheetsApiUrl,
    spreadsheetId,
    performanceScheduleSheetRange,
    hostScheduleSheetRange,
    configSheetRange,
    sheetsAccessKey
}