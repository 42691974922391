import { spreadsheetId, hostScheduleSheetRange } from "../../../env"
import { fetchGoogleSheetValues, MajorDimension } from "../../services/googleSheets";
import { HostEvent } from "./model/hostEvent";
import moment from "moment/moment";

const NAME_COL_INDEX = 0;
const PRONOUNS_COL_INDEX = 1;
const TWITCH_LINK_COL_INDEX = 2;
const START_TIME_COL_INDEX = 3;
const END_TIME_COL_INDEX = 4;

class HostEventBroker {

    async getEvents() : Promise<Array<HostEvent>> {
        const events:Array<HostEvent> = [];

        const response = await fetchGoogleSheetValues(spreadsheetId, hostScheduleSheetRange, MajorDimension.ROWS);
        const responseContent = await response.json();
        const rows:Array<Array<string>> = responseContent.values;
        for (let row of rows) {
            if (!row[NAME_COL_INDEX] || row[NAME_COL_INDEX].trim() === "")
                continue;
            const startDate = moment.parseZone(row[START_TIME_COL_INDEX] + " -05:00", `DD/MM/YYYY HH:mm:SS ZZ`);
            const endDate = moment.parseZone(row[END_TIME_COL_INDEX] + " -05:00", `DD/MM/YYYY HH:mm:SS ZZ`);
            const event = new HostEvent(
                row[NAME_COL_INDEX],
                row[PRONOUNS_COL_INDEX],
                row[TWITCH_LINK_COL_INDEX],
                startDate,
                endDate);
            events.push(event);
        }
        return events;
    }

}

const hostEventBroker = new HostEventBroker();
export { hostEventBroker };
