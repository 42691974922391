import "./host.css";

import React  from "react";

export interface EventItemProps {
    name: string;
    pronouns: string;
    twitch: string;
}

export const Host = ({ name, pronouns, twitch } : EventItemProps) => {
    const twitchLink = `https://twitch.tv/${twitch || ""}`;

    return <div className="host__container">
            <div className="host__content-container">
                <h3 className="host__name">
                    {name}
                </h3>
                <p className="host__pronouns">
                    {pronouns}
                </p>
                {twitch &&
                    <h4 className="host__twitch">
                        <i className="fa fa-twitch" />
                        <a href={twitchLink} target="_blank" rel="noreferrer">{twitch}</a>
                    </h4>
                }
            </div>
        </div>;
}
