import "./host.css";

import { hostEventBroker, HostEvent } from "../../../domain/events/host-events";
import { useState, useEffect } from "react"
import { Host } from "../../components/host/host";


export const HostPage = () => {
    const [ events, setEvents ] = useState([] as Array<HostEvent>);

    useEffect(() => {
        async function getEvents() {
            const events = await hostEventBroker.getEvents();
            setEvents(events);
        }
        getEvents().then(_ => {
            setInterval(getEvents, 5000);
        });
    }, [])

    if (events.length === 0)
        return <>Loading...</>;

    const now = new Date();
    const currentHost = events.find(e => e.endTime.isAfter(now));

    return <>
        <div className="host-schedule__container">
            { currentHost && <Host {...currentHost} /> }
        </div>
    </>;
}