import { sheetsAccessKey, sheetsApiUrl } from "../../../env";
import { MajorDimension } from "./majorDimension";

const fetchGoogleSheetValues = (spreadsheetId:string, range:string, asColumns:MajorDimension):Promise<Response>  => {

    return fetch(`${sheetsApiUrl}${spreadsheetId}/values/${range}?key=${sheetsAccessKey}&majorDimension=${asColumns}`)
        .then(r => {
            if (r.status === 403)
                throw new Error("Unauthorized response from API, ensure sheet is publicly available and the Access Key ");
            return r;
        });
}

export { fetchGoogleSheetValues };